import React from 'react';
import LanguageContext from './Language';

function LangContextWrapper({ children }) {
  const [lang, setLang] = React.useState('en');

  React.useEffect(() => {
    const userLang = localStorage.getItem('lang');
    if (userLang) {
      setLang(userLang);
    }
  }, []);

  return (
    <>
      <LanguageContext.Provider value={{ lang, setLang }}>
        {children}
      </LanguageContext.Provider>
    </>
  );
}

export default LangContextWrapper;
